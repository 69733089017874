.post {
  &_item {
    background: #0054a0; } }

.sidebar {
  margin-top: 2rem;
  visibility: hidden; }

.sidebar_inner {
  border-radius: 0.5rem;
  background: #0054a0; }

.serverstatus {
  border-radius: 0.5rem;
  overflow: hidden; }

.smiley {
  padding: 0px;
  margin: 0px;
  border: 0px; }

.excerpt_header {
  padding-top: 0.1rem; }

.excerpt_footer {
  padding-bottom: 1.5rem; }
